import FooterWPPostsView from './FooterWPPostsView'
import { useLatestBlogPosts } from '../../../hooks/useLatestBlogPosts'
import { graphql, useStaticQuery } from 'gatsby'

export interface IFooterWPPostsProps {
  count?: number
  nofollow?: boolean
}

const FooterWPPosts = (props: IFooterWPPostsProps) => {
  const posts = useLatestBlogPosts(props.count || 3)

  return (
    <FooterWPPostsView posts={posts} nofollow={props.nofollow} />
  )
}

export default FooterWPPosts

export const useFooterWPPosts = (postsLimit = 3) => {
  const { allStrapiBlogPost } = useStaticQuery(graphql`
    query {
      allStrapiBlogPost(
        sort: { date:DESC }
      ) {
        nodes {
          title
          slug
        }
      }
    }
  `)

  const limitedPosts = allStrapiBlogPost.nodes.slice(0, postsLimit)

  return limitedPosts
}

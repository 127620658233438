import { styled } from '@mui/material'

export const StyledSection = styled('section')(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  position: 'relative',
}))

export interface IBackgroundColorProps {
  color: string
}

export const BackgroundColor = styled('div')<IBackgroundColorProps>(({ color }) => ({
  position: 'absolute',
  background: color,
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  zIndex: -1,
}))
